import {
    addPropertyControls,
    ControlType,
    RenderTarget,
    // @ts-ignore
    ResolveLinks,
    // @ts-ignore
    useQueryData,
} from "framer"
import { motion } from "framer-motion"
import { cloneElement, useState, useEffect, useRef, createContext } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"

export const usePaginationStore = createStore({})
export const useFilterStore = createStore({})
export const useFavouriteStore = createStore({})

export const SuperfieldsContext = createContext({ active: false })

const FAVOURITES_FILTER_ID = "[{(FAVOURITES)}]"
const MULTI_SELECT_DELIMITER = "_&%#|_"

/**
 * @framerSupportedLayoutWidth any-prefer-fixed
 * @framerSupportedLayoutHeight any
 * @framerIntrinsicWidth 600
 */
export default function Superfields(props) {
    const {
        superfieldsId,
        layout,
        componentConfig,
        pagination,
        paginationType,
        itemsPerPage,
        filtering,
        favouriting,
        cmsCollectionName,
        slugFieldName,
        favouritesOnly,
    } = props
    const collectionList = props.collectionList?.[0]
    const emptyState = props.emptyState?.[0]
    const query = collectionList?.props?.children?.props?.query
    const collectionData = query?.from?.data
    const isCanvas = RenderTarget.current() === RenderTarget.canvas

    const scrollUpRef = useRef(null)

    // Get property controls
    const propertyControlsByName = {}
    const propertyControlsById = collectionData?.propertyControls || {}

    for (const id in propertyControlsById) {
        const control = propertyControlsById[id]
        propertyControlsByName[control.title] = { id, ...control }
    }

    const slugFieldId = propertyControlsByName[slugFieldName]?.id

    // Get CMS data
    const queriedDataIds = query
        ? Object.values(useQueryData(query)).map((item) => item.id)
        : null
    const queriedData = queriedDataIds
        ? collectionData.filter((item) => queriedDataIds.includes(item.id))
        : null
    let data = queriedData

    const [paginationState, setPaginationState] = usePaginationStore()
    const [filterState, setFilterState] = useFilterStore()
    const [favouriteState, setFavouriteState] = useFavouriteStore()

    useEffect(() => {
        if (favouriting) {
            if (!favouriteState[cmsCollectionName]) {
                setFavouriteState({ [cmsCollectionName]: [] })
            }
        }

        setFilterState({})
    }, [])

    function onPaginationChange() {
        if (
            props.scrollUp &&
            scrollUpRef.current &&
            paginationType == "prevNextButtons"
        ) {
            const rect = scrollUpRef.current.getBoundingClientRect()
            const topIsInView = rect.top >= 0 && rect.top <= window.innerHeight

            if (!topIsInView) {
                scrollUpRef.current.scrollIntoView({ behavior: "smooth" })
            }
        }
    }

    let scheduleUpdatePaginationState = false

    const updatePaginationState = () => {
        if (pagination && Array.isArray(data)) {
            const totalPages = Math.ceil(data.length / itemsPerPage)

            if (totalPages != paginationState[superfieldsId]?.totalPages) {
                setPaginationState({
                    [superfieldsId]: {
                        page: 0,
                        totalPages,
                        active: true,
                        onPaginationChange,
                    },
                })
            }
        }
    }

    // Filtering
    const filters = filterState[superfieldsId]
    if (!isCanvas && filters) {
        const newData = []

        for (const index in data) {
            const cmsItem = data[index]

            if (!isNaN(Number(index))) {
                let isValid = true

                for (const fieldName in filters) {
                    if (fieldName == FAVOURITES_FILTER_ID) {
                        continue
                    }

                    const filter = filters[fieldName]
                    const field = propertyControlsByName[fieldName]
                    const value = filter?.value

                    if (
                        filter &&
                        field &&
                        value != null &&
                        value != undefined
                    ) {
                        if (typeof value == "string" && filter.multiSelect) {
                            const values = value.split(MULTI_SELECT_DELIMITER)
                            let containsNone = true

                            switch (field.type) {
                                case ControlType.String:
                                    if (values.includes(cmsItem[field.id])) {
                                        containsNone = false
                                    }
                                    break
                                case ControlType.Enum:
                                    for (const v of values) {
                                        if (
                                            cmsItem[field.id] ==
                                            field.options?.[
                                                field.optionTitles?.indexOf(v)
                                            ]
                                        ) {
                                            containsNone = false
                                        }
                                    }
                            }

                            if (containsNone) {
                                isValid = false
                                break
                            }
                        } else {
                            switch (field.type) {
                                case ControlType.Boolean:
                                case ControlType.String:
                                    if (cmsItem[field.id] != value) {
                                        isValid = false
                                    }
                                    break
                                case ControlType.Enum:
                                    if (
                                        cmsItem[field.id] !=
                                        field.options?.[
                                            field.optionTitles?.indexOf(value)
                                        ]
                                    )
                                        isValid = false
                                    break
                            }
                        }
                    }

                    if (!isValid) {
                        break
                    }
                }

                if (isValid) {
                    newData.push(cmsItem)
                }
            } else {
                newData[index] = cmsItem
            }
        }

        data = newData
        scheduleUpdatePaginationState = true
    }

    // Favourites filter
    if (
        !isCanvas &&
        slugFieldId &&
        favouriteState[cmsCollectionName] &&
        (favouritesOnly || filters?.[FAVOURITES_FILTER_ID]?.value)
    ) {
        const newData = []
        for (const index in data) {
            const cmsItem = data[index]

            if (!isNaN(Number(index))) {
                if (
                    favouriteState[cmsCollectionName].includes(
                        cmsItem[slugFieldId]
                    )
                ) {
                    newData.push(cmsItem)
                }
            } else {
                newData[index] = cmsItem
            }
        }

        data = newData
        scheduleUpdatePaginationState = true
    }

    if (scheduleUpdatePaginationState) {
        updatePaginationState()
    }

    // Number of items removed from beginning of array by pagination
    let childrenStartOffset = 0

    // Pagination
    let items = data
    if (!isCanvas && pagination) {
        const state = paginationState[superfieldsId]
        if (state) {
            switch (paginationType) {
                case "prevNextButtons":
                    items = items.slice(
                        state.page * itemsPerPage,
                        state.page * itemsPerPage + itemsPerPage
                    )
                    childrenStartOffset = state.page * itemsPerPage
                    break
                case "loadMoreButton":
                case "infiniteScroll":
                    items = items.slice(0, (state.page + 1) * itemsPerPage)
                    break
            }
        }
    }

    useEffect(updatePaginationState, [collectionData])

    if (!collectionList) {
        return (
            <Message
                title="Connect a collection list"
                subtitle="Drag the handle on the right side to a collection list or select from the dropdown list. The collection list must be outside of a page to be connected."
            />
        )
    }

    if (favouriting && cmsCollectionName == "") {
        return (
            <Message
                title="Enter a CMS Collection Name"
                subtitle='Write the name of the CMS collection in the "CMS Collection Name" property. The name is used for saving favourites as a cookie and for matching with favourite buttons.'
            />
        )
    }

    if (favouriting && slugFieldName == "") {
        return (
            <Message
                title="Enter a Slug Field Name"
                subtitle='Write the name of the field in the "Slug Field Name" property. "Slug" is the default value unless you renamed it.'
            />
        )
    }

    let showNotComponentChildError = false

    let children = []
    if (!isCanvas && collectionList && query) {
        const { offset, limit, ...otherQuery } = query
        children = collectionList.props?.children?.props?.children(
            useQueryData({
                ...otherQuery,
                from: {
                    ...query.from,
                    data: items,
                },
            })
        )

        if (
            componentConfig &&
            componentConfig.variant.length > 0 &&
            children?.length
        ) {
            const newChildren = []
            let isComponentWithVariants = true

            for (let i = 0; i < children.length; i++) {
                const child = children[i]
                const componentProps =
                    child?.props?.children?.props?.children?.props

                // Component types:
                // default: variant prop is 0 levels down
                // links: use ResolvedLinks and go 2 levels down
                // child: variant prop is 1 levels down
                let componentType = "default"
                let component = componentProps?.children

                if (typeof component == "function" && componentProps?.links) {
                    componentType = "links"
                    component = ResolveLinks({
                        links: componentProps.links,
                        children: componentProps.children,
                    })
                } else if (!component?.type?.propertyControls) {
                    componentType = "child"
                }

                const cData =
                    componentType == "links"
                        ? component?.props?.children?.props?.children
                        : componentType == "child"
                          ? component?.props?.children
                          : component
                if (component && cData) {
                    const variantProp = cData.type?.propertyControls?.variant
                    if (variantProp) {
                        if (componentType == "link") {
                            newChildren.push(
                                cloneElement(component, {
                                    children: {
                                        ...component.props.children,
                                        props: {
                                            ...component.props.children.props,
                                            children: {
                                                ...cData,
                                                props: {
                                                    ...cData.props,
                                                    variant:
                                                        componentConfig.variant,
                                                },
                                            },
                                        },
                                    },
                                })
                            )
                        } else if (componentType == "child") {
                            newChildren.push(
                                cloneElement(component, {
                                    children: {
                                        ...cData,
                                        props: {
                                            ...cData.props,
                                            variant: componentConfig.variant,
                                        },
                                    },
                                })
                            )
                        } else {
                            newChildren.push(
                                cloneElement(component, {
                                    variant: componentConfig.variant,
                                })
                            )
                        }
                    } else {
                        isComponentWithVariants = false
                        break
                    }
                }
            }

            if (isComponentWithVariants) {
                children = newChildren
            }
        }
    }

    if (showNotComponentChildError) {
        return (
            <Message
                title="Collection List item is not a component with variants."
                subtitle="To use variant overrides, make sure the only item in the Collection List is a component with variants. Otherwise, remove the component variant override."
            />
        )
    }

    let layoutStyle = {}
    if (layout) {
        const baseLayoutStyle = {
            gap: layout.gap,
            padding: layout.paddingIsMixed
                ? `${layout.paddingTop}px ${layout.paddingRight}px ${layout.paddingBottom}px ${layout.paddingLeft}px`
                : `${layout.padding}px`,
        }

        switch (layout.type) {
            case "stack":
                const isVertical = layout.direction == "vertical"
                layoutStyle = {
                    display: "flex",
                    flexDirection: isVertical ? "column" : "row",
                    flexWrap: layout.wrap ? "wrap" : "nowrap",
                    alignItems: isVertical ? layout.alignV : layout.alignH,
                    justifyContent: layout.distribute,
                    ...baseLayoutStyle,
                }
                break
            case "grid":
                // Columns
                let gridTemplateColumns = ""
                if (layout.columns == "auto") {
                    if (layout.gridWidthType == "min") {
                        gridTemplateColumns = `repeat(auto-fill, minmax(${layout.gridWidth}px, 1fr))`
                    } else {
                        // "fixed"
                        gridTemplateColumns = `repeat(auto-fill, ${layout.gridWidth}px)`
                    }
                } else {
                    if (layout.gridWidthType == "min") {
                        gridTemplateColumns = `repeat(${layout.columnCount}, minmax(${layout.gridWidth}px, 1fr))`
                    } else {
                        // "fixed"
                        gridTemplateColumns = `repeat(${layout.columnCount}, ${layout.gridWidth}px)`
                    }
                }

                // Rows
                let gridAutoRows = ""
                switch (layout.gridHeightType) {
                    case "fixed":
                        gridAutoRows = `${layout.gridHeight}px`
                        break
                    case "fill":
                        gridAutoRows = "minmax(0px, 1fr)"
                        break
                    case "fit":
                        gridAutoRows = "min-content"
                        break
                }

                layoutStyle = {
                    display: "grid",
                    gridTemplateColumns,
                    gridAutoRows,
                    justifyContent: layout.gridAlign,
                    alignItems: "center",
                    ...baseLayoutStyle,
                }

                break
        }
    }

    // Canvas view
    if (isCanvas) {
        if (collectionList) {
            const id = `a${Math.floor(Math.random() * 100000)}`
            return (
                <>
                    {cloneElement(collectionList, {
                        id,
                        style: {
                            ...collectionList.props?.style,
                            ...props.style,
                            ...layoutStyle,
                        },
                    })}
                    {pagination && (
                        <style>{`#${id} > *:nth-child(n+${
                            itemsPerPage + 1
                        }) { display: none !important; }`}</style>
                    )}
                </>
            )
        } else {
            return <div style={{ height: 100 }} />
        }
    }

    // Empty state
    if (!items?.length) {
        if (emptyState) {
            return cloneElement(emptyState, {
                style: {
                    ...emptyState.props.style,
                    ...props.style,
                },
            })
        } else {
            return <div />
        }
    }

    return (
        <div
            {...collectionList.props}
            style={{
                ...collectionList?.props.style,
                ...props.style,
                position: "relative",
                overflow:
                    pagination && paginationType == "infiniteScroll"
                        ? "hidden auto"
                        : undefined,
                ...layoutStyle,
            }}
        >
            <SuperfieldsContext.Provider value={{ active: true }}>
                {children.map((child, i) => (
                    <child.type {...child.props} key={child.props.id} />
                ))}
            </SuperfieldsContext.Provider>
            {props.scrollUp && (
                <div
                    ref={scrollUpRef}
                    style={{
                        position: "absolute",
                        top: props.scrollUp.offset,
                        pointerEvents: "none",
                    }}
                />
            )}
        </div>
    )
}

addPropertyControls(Superfields, {
    superfieldsId: {
        type: ControlType.Number,
        defaultValue: 0,
        step: 1,
        min: 0,
        displayStepper: true,
        title: "ID",
    },
    collectionList: {
        type: ControlType.ComponentInstance,
    },
    emptyState: {
        type: ControlType.ComponentInstance,
    },
    layout: {
        type: ControlType.Object,
        optional: true,
        buttonTitle: "Options",
        controls: {
            type: {
                type: ControlType.Enum,
                defaultValue: "stack",
                options: ["stack", "grid"],
                optionTitles: ["Stack", "Grid"],
                displaySegmentedControl: true,
            },
            direction: {
                type: ControlType.Enum,
                defaultValue: "vertical",
                options: ["horizontal", "vertical"],
                displaySegmentedControl: true,
                hidden: (props) => props.type != "stack",
            },
            distribute: {
                type: ControlType.Enum,
                defaultValue: "start",
                options: [
                    "start",
                    "center",
                    "end",
                    "space-between",
                    "space-around",
                    "space-evenly",
                ],
                optionTitles: [
                    "Start",
                    "Center",
                    "End",
                    "Space Between",
                    "Space Around",
                    "Space Evenly",
                ],
                hidden: (props) => props.type != "stack",
            },
            alignH: {
                type: ControlType.Enum,
                defaultValue: "start",
                options: ["start", "center", "end"],
                optionTitles: ["Start", "Center", "End"],
                optionIcons: ["align-top", "align-middle", "align-bottom"],
                displaySegmentedControl: true,
                title: "Align",
                hidden: (props) =>
                    props.type != "stack" || props.direction != "horizontal",
            },
            alignV: {
                type: ControlType.Enum,
                defaultValue: "start",
                options: ["start", "center", "end"],
                optionTitles: ["Start", "Center", "End"],
                optionIcons: ["align-left", "align-center", "align-right"],
                displaySegmentedControl: true,
                title: "Align",
                hidden: (props) =>
                    props.type != "stack" || props.direction != "vertical",
            },
            wrap: {
                type: ControlType.Boolean,
                defaultValue: false,
                hidden: (props) => props.type != "stack",
            },
            columns: {
                type: ControlType.Enum,
                defaultValue: "fixed",
                options: ["auto", "fixed"],
                optionTitles: ["Auto", "Fixed"],
                displaySegmentedControl: true,
                hidden: (props) => props.type != "grid",
            },
            columnCount: {
                type: ControlType.Number,
                defaultValue: 2,
                min: 1,
                step: 1,
                displayStepper: true,
                title: " ",
                hidden: (props) =>
                    props.type != "grid" || props.columns == "auto",
            },
            gridWidthType: {
                type: ControlType.Enum,
                defaultValue: "min",
                options: ["min", "fixed"],
                optionTitles: ["Min", "Fixed"],
                title: "Width",
                hidden: (props) => props.type != "grid",
            },
            gridWidth: {
                type: ControlType.Number,
                defaultValue: 200,
                min: 1,
                step: 1,
                title: " ",
                hidden: (props) => props.type != "grid",
            },
            gridHeightType: {
                type: ControlType.Enum,
                defaultValue: "fixed",
                options: ["fixed", "fill", "fit"],
                optionTitles: ["Fixed", "Fill Container", "Fit Content"],
                title: "Height",
                hidden: (props) => props.type != "grid",
            },
            gridHeight: {
                type: ControlType.Number,
                defaultValue: 200,
                min: 1,
                step: 1,
                title: " ",
                hidden: (props) =>
                    props.type != "grid" || props.gridHeightType != "fixed",
            },
            gridAlign: {
                type: ControlType.Enum,
                defaultValue: "center",
                options: ["start", "center", "end"],
                optionTitles: ["Left", "Center", "Right"],
                displaySegmentedControl: true,
                title: "Align",
                hidden: (props) =>
                    props.type != "grid" ||
                    (props.gridHeightType == "fixed" &&
                        props.gridWidthType == "min"),
            },
            gap: {
                type: ControlType.Number,
                defaultValue: 10,
                min: 0,
                step: 1,
            },
            padding: {
                type: ControlType.FusedNumber,
                defaultValue: 0,
                toggleKey: "paddingIsMixed",
                toggleTitles: ["All", "Individual"],
                valueKeys: [
                    "paddingTop",
                    "paddingRight",
                    "paddingBottom",
                    "paddingLeft",
                ],
                valueLabels: ["T", "R", "B", "L"],
                min: 0,
            },
        },
    },
    componentConfig: {
        type: ControlType.Object,
        optional: true,
        title: "Component",
        description: " ",
        controls: {
            variant: {
                type: ControlType.String,
                defaultValue: "",
                placeholder: "Variant Name",
                description:
                    "Override the component's variant for responsive design.\n\n*Note:* The component must be the only layer inside the Collection List to use this.",
            },
        },
    },
    pagination: {
        type: ControlType.Boolean,
        defaultValue: false,
    },
    paginationType: {
        type: ControlType.Enum,
        defaultValue: "pagination",
        options: ["prevNextButtons", "loadMoreButton", "infiniteScroll"],
        optionTitles: [
            "Prev/Next Buttons",
            "Load More Button",
            "Infinite Scroll",
        ],
        title: "Type",
        hidden: (props) => !props.pagination,
    },
    itemsPerPage: {
        type: ControlType.Number,
        defaultValue: 4,
        min: 1,
        step: 1,
        displayStepper: true,
        hidden: (props) => !props.pagination,
    },
    scrollUp: {
        type: ControlType.Object,
        optional: true,
        defaultValue: { offset: 0 },
        controls: {
            offset: {
                type: ControlType.Number,
                defaultValue: -32,
                step: 1,
                description:
                    "When the page is changed, scroll up to the top of the Superfields component with an offset in px.",
            },
        },
        hidden: (props) =>
            !props.pagination || props.paginationType != "prevNextButtons",
    },
    filtering: {
        type: ControlType.Boolean,
        defaultValue: false,
    },
    favouriting: {
        type: ControlType.Boolean,
        defaultValue: false,
    },
    cmsCollectionName: {
        type: ControlType.String,
        defaultValue: "",
        placeholder: "CMS Collection Name",
        title: "CMS Collection Name",
        hidden: (props) => !props.favouriting,
    },
    slugFieldName: {
        type: ControlType.String,
        defaultValue: "Slug",
        placeholder: "Slug Field Name",
        hidden: (props) => !props.favouriting,
    },
    favouritesOnly: {
        type: ControlType.Boolean,
        defaultValue: false,
        hidden: (props) => !props.favouriting,
    },
})

export const borderPropertyControl = {
    type: ControlType.Object,
    optional: true,
    controls: {
        color: {
            type: ControlType.Color,
            defaultValue: "#222",
        },
        width: {
            type: ControlType.FusedNumber,
            defaultValue: 1,
            toggleKey: "widthIsMixed",
            toggleTitles: ["All", "Individual"],
            valueKeys: ["widthTop", "widthRight", "widthBottom", "widthLeft"],
            valueLabels: ["T", "R", "B", "L"],
            min: 0,
        },
        style: {
            type: ControlType.Enum,
            defaultValue: "solid",
            options: ["solid", "dashed", "dotted", "double"],
            optionTitles: ["Solid", "Dashed", "Dotted", "Double"],
        },
    },
}

export const shadowPropertyControl = {
    type: ControlType.Object,
    optional: true,
    controls: {
        color: {
            type: ControlType.Color,
            defaultValue: "rgba(0, 0, 0, 0.25)",
        },
        x: {
            type: ControlType.Number,
            defaultValue: 0,
            displayStepper: true,
        },
        y: {
            type: ControlType.Number,
            defaultValue: 2,
            displayStepper: true,
        },
        blur: {
            type: ControlType.Number,
            defaultValue: 4,
            min: 0,
            displayStepper: true,
        },
        spread: {
            type: ControlType.Number,
            defaultValue: 0,
            displayStepper: true,
        },
    },
}

export function Border({
    width,
    widthIsMixed,
    widthTop,
    widthRight,
    widthBottom,
    widthLeft,
    style,
    color,
    borderRadius,
    transition,
}) {
    return (
        <motion.div
            animate={{
                borderColor: color,
            }}
            style={{
                position: "absolute",
                inset: 0,
                borderWidth: widthIsMixed
                    ? `${widthTop}px ${widthRight}px ${widthBottom}px ${widthLeft}px`
                    : `${width}px`,
                borderStyle: style,
                borderRadius: borderRadius,
                pointerEvents: "none",
            }}
            initial={false}
            transition={transition}
        />
    )
}

export function Message({ title, subtitle }) {
    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                height: "100%",
                placeContent: "center",
                placeItems: "center",
                flexDirection: "column",
                gap: 16,
                backgroundColor: "rgba(136, 85, 255, 0.1)",
                borderRadius: 6,
                border: "1px dashed rgb(136, 85, 255)",
                color: "rgb(136, 85, 255)",
                fontSize: 16,
                padding: 20,
                minHeight: 200,
                textWrap: "balance",
            }}
        >
            <p
                style={{
                    margin: 0,
                    fontWeight: 600,
                    textAlign: "center",
                }}
            >
                {title}
            </p>
            <p
                style={{
                    margin: 0,
                    opacity: 0.7,
                    lineHeight: 1.5,
                    textAlign: "center",
                }}
            >
                {subtitle}
            </p>
        </div>
    )
}
